<template>
    <el-table :data="clientsMaped" v-loading="$waiting.is('loading')" class="w-full">
        <el-table-column prop="number" label="Nr" width="90" sortable />
        <el-table-column prop="name" label="Företag" sortable />
        <el-table-column prop="bankBalance" label="Banksaldo" align="right" width="120" sortable>
            <template slot-scope="scope">
                {{ scope.row.bankBalance | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="bookingBalance" label="Bokf. saldo" align="right" width="120" sortable>
            <template slot-scope="scope">
                {{ scope.row.bookingBalance | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="diff" label="Diff" align="right" width="120" sortable>
            <template slot-scope="scope">
                {{ scope.row.diff | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="receiptProposalCount" label="Väntar" align="right" width="100" sortable />
        <el-table-column prop="receiptRegisteredCount" label="Reg." align="right" width="100" sortable />
        <el-table-column prop="unMatched" label="Omatchad" align="right" width="120" sortable />
    </el-table>
</template>
<script>
export default {
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        clientsMaped: function() {
            return this.clients.map(item => ({
                number: item.number,
                name: item.name,
                bankBalance: item.bankBalance / 100,
                bookingBalance: item.bookingBalance / 100,
                diff: (item.bankBalance - item.bookingBalance) / 100,
                receiptProposalCount: item.receiptProposalCount,
                receiptRegisteredCount: item.receiptRegisteredCount,
                unMatched: item.unMatched,
            }));
        },
    },
};
</script>
