<template>
    <ClientsTable :clients="clients" />
</template>
<script>
import Api from "./conciliationList.api.js";
import ClientsTable from "./ui/ClientsTable.vue";
export default {
    components: {
        ClientsTable,
    },

    data: function() {
        return {
            clients: [],
        };
    },

    watch: {
        async isGlobalClientSelected() {
            this.getList();
        },
    },

    created() {
        this.getList();
        document.title = "Bank - Lista";
    },

    methods: {
        async getList() {
            this.$waiting.start("loading");
            if (this.isGlobalClientSelected) {
                this.clients = await Api.getClientBalance(this.$store.state.topBar.selectedClient.value);
            } else {
                this.clients = await Api.getClientsBalance();
            }
            this.$waiting.end("loading");
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
