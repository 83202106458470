import Vue from "vue";

export default {
    getClientsBalance: async () => {
        const response = await Vue.axios.get(`bankstatements/getClientsBalance`);
        return response.data;
    },

    getClientBalance: async clientId => {
        const response = await Vue.axios.get(`bankstatements/getClientsBalance/${clientId}`);
        return response.data;
    },
};
